@import '../../../../resources/sass/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.bx-soa { padding-bottom: 20px; }

.bx-soa-section {
	margin-bottom: map-get($spacers, 6);
	background-color: $white;
	transition: border-color .3s ease;

	box-shadow: $block-shadow-default;
}

.bx-soa-section-title-count {
	position: relative;
	display: inline-block;
	margin: -.19rem 2.19rem 0 0;
	min-width: 1rem;
	height: 1rem;
	border: 1px solid #98979d;
	border-radius: 8px;
	vertical-align: middle;
}

#bx-soa-order {
	counter-reset: heading;
	transition: 500ms opacity ease;
}

.bx-soa-section .bx-soa-section-title-count:after {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	color: #98979d;
	content: counter(heading);
	counter-increment: heading;
	text-align: center;
	font-size: 10px;
	line-height: 15px;
	transition: all .3s ease;
}

.bx-soa-section-title-count:before {
	position: absolute;
	right: -5px;
	bottom: -2px;
	display: none;
	width: 10px;
	height: 10px;
	background: url(images/sprite.png) no-repeat center;
	content: "";
}

.bx-soa-section.bx-step-completed .bx-soa-section-title-count {
	border-color: #87c73d;
	background: #87c73d;
}

.bx-soa-section.bx-step-completed .bx-soa-section-title-count:after { color: #fff; }

.bx-soa-section.bx-step-completed .bx-soa-section-title-count:before {
	display: block;
	background-position: center 0;
}

.bx-soa-section.bx-step-warning .bx-soa-section-title-count {
	border-color: #f8c11a;
	background: #f8c11a;
}

.bx-soa-section.bx-step-warning .bx-soa-section-title-count:after { color: $body-color; }

.bx-soa-section.bx-step-warning .bx-soa-section-title-count:before {
	display: block;
	background-position: center -37px;
}

.bx-soa-section.bx-step-error .bx-soa-section-title-count {
	border-color: #db4c4c;
	background: #db4c4c;
}

.bx-soa-section.bx-step-error .bx-soa-section-title-count:after { color: #fff; }


.bx-soa-section.bx-step-error .bx-soa-section-title-count:before {
	display: block;
	background-position: center -20px;
}

.bx-soa-section-title-container {
	overflow: hidden;
	padding: 1rem 5.3rem 1rem;
	background: $white;
	font-size: $font-size-base;
	border-bottom: $body-bg 1px solid;
}

.bx-soa-section-title {
	margin: 0;
	min-height: 4.69rem;
	color: $body-color;
	vertical-align: middle;
	font-size: 1.25rem;
	line-height: $line-height-base;
	transition: all .3s ease;
	display: flex;
	align-items: center;
}

.bx-soa-editstep {
	display: inline;
	vertical-align: middle;
	text-decoration: none !important;
	font-size: 1rem;
}

.bx-soa-section.bx-selected .bx-soa-editstep { display: none; }

.bx-soa-editstep:hover { border-bottom-color: transparent !important; }

.bx-soa-section-content {
	padding: 2.5rem 5.3rem 2rem;
	transition: all .2s ease;
}

/*.bx-soa-section.bx-step-completed .bx-soa-section-content,*/
/*.bx-soa-section.bx-selected .bx-soa-section-content { line-height: 18px; }*/

/* Table */
.bx-soa-table-fade {
	position: relative;
}

.bx-soa-table-fade:before {
	left: 0;
	background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.bx-soa-table-fade:after {
	right: 0;
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.bx-soa-table-fade:before,
.bx-soa-table-fade:after {
	position: absolute;
	top: 0;
	bottom: 10px;
	display: block;
	width: 0;
	height: 100%;
	content: "";
	transition: width 300ms ease;
}

.bx-soa-table-fade-left:before,
.bx-soa-table-fade-right:after { width: 15px; }

.bx-soa-item-table {
	display: table;
	margin-bottom: 10px;
	min-width: 100%;
}

.bx-soa-item-tr { display: table-row; }

.bx-soa-item-td {
	display: table-cell;
	padding-top: 10px;
	border-top: 1px dotted #e5e5e5;
	vertical-align: top;
	transition: all .2s ease;
}

@media (min-width: 769px) {
	.bx-soa-item-td {
		padding-right: 7px;
		padding-bottom: 10px;
		padding-left: 7px;
	}
}

.bx-soa-item-table .bx-soa-item-tr:first-child .bx-soa-item-td { border-top: none }

.bx-soa-item-img-block {
	float: left;
	padding-right: 15px;
}

.bx-step-completed .bx-soa-item-img-block {
	padding-right: 10px;
	padding-bottom: 10px;
	width: 48px;
}

.bx-soa-item-imgcontainer {
	padding-top: 100%;
	border: 1px solid #c0cfd9;
	border-radius: 4px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.bx-soa-item-content { }

.bx-soa-item-title {
	padding-bottom: 5px;
	font-weight: bold;

	a {
		color: $body-color;
	}
}

.bx-soa-item-td-title {
	padding-bottom: 3px;
	color: $extra;
	font-size: $small-font-size
}

.bx-soa-item-td-text {
	vertical-align: middle;
	line-height: 25px;
}

.bx-soa-item-properties .bx-soa-item-td-title,
.bx-soa-item-properties .bx-soa-item-td-text { text-align: center; }

.bx-soa-item-properties.bx-text-right .bx-soa-item-td-title,
.bx-soa-item-properties.bx-text-right .bx-soa-item-td-text { text-align: right; }

.bx-soa-item-td-text input[type="number"] {
	height: 25px;
	line-height: 20px;
}

.bx-soa-item-td-text .bx-price {
	color: $body-color;
	white-space: nowrap;
}

.bx-price-old {
	color: $extra;
	text-decoration: line-through;
	white-space: nowrap;
	font-weight: 400;
	font-size: $small-font-size;
}

.bx-soa-item-td-control {
	padding-bottom: 5px;
	font-size: 11px;
}

@media (max-width: 991px) {
	.bx-soa-item-table,
	.bx-soa-item-table > tbody,
	.bx-soa-item-tr,
	.bx-soa-item-td { display: block; }

	.bx-soa-item-tr.bx-soa-item-info-container,
	.bx-soa-item-tr .bx-soa-item-td { border: none; }

	.bx-soa-item-tr {
		overflow: hidden;
		padding-top: 10px;
		padding-bottom: 15px;
		border-top: 1px dotted #e5e5e5;
	}

	.bx-soa-item-tr.bx-soa-item-tr-first { border: none; }

	.bx-soa-item-block { overflow: hidden; }

	.bx-soa-item-properties.bx-text-right .bx-soa-item-td-title,
	.bx-soa-item-properties.bx-text-right .bx-soa-item-td-text { text-align: center; }
}

@media (min-width: 581px) and (max-width: 991px) {
	.bx-soa-item-properties { float: left; padding-bottom: 10px; }

	.bx-soa-item-td-title,
	.bx-soa-item-nth-4p1,
	.bx-soa-item-tr { clear: both; }
}

@media (max-width: 580px) {
	.bx-soa-item-td { line-height: 17px; }

	.bx-soa-item-img-block {
		float: none;
		margin: 0 auto;
		padding-right: 0;
		max-width: 160px;
	}

	.bx-soa-section.bx-step-completed .bx-soa-item-img-block { float: left; }

	.bx-soa-item-content { padding-top: 15px; }

	.bx-soa-section.bx-step-completed .bx-soa-item-content { padding-top: 0; }

	.bx-scu-container { padding-top: 10px; }

	.bx-soa-item-td-title,
	.bx-soa-item-td-text {
		display: inline-block !important;
		padding: 2px 5px;
		vertical-align: top;
		text-align: left;
		font-size: 13px;
		line-height: 20px;
	}

	.bx-soa-item-td-title:after { content: ":" }

	.bx-soa-item-properties { padding: 0; }
}

@media (min-width: 580px) {
	.bx-soa-item-img-block {
		margin: 0;
		width: 85px;
	}
}

/* Advanced info */
.bx-soa-item-info-container {
	position: relative;
	height: 35px;
}

.bx-soa-item-info-container .bx-soa-item-td {
	position: absolute;
	border: none;
}

.bx-soa-item-info-container .bx-soa-item-info-block {
	overflow: hidden;
	margin-bottom: 10px;
	height: 0;
}

.bx-soa-item-info-block.bx-active {
	padding-top: 10px;
	height: auto;
}

.bx-soa-info-block {
	max-width: 100%;
	border: none;
}

.bx-soa-info-line { line-height: 15px; }

.bx-soa-info-title {
	padding: 4px 0;
	color: #7c7c7c;
	vertical-align: top;
	font-size: 12px;
}

.bx-soa-info-text {
	display: inline-block;
	padding: 4px 10px;
	vertical-align: top;
	font-size: 12px;
}

@media (max-width: 580px) {
	.bx-soa-item-info-container .bx-soa-item-td {
		position: static;
		right: auto;
		left: auto;
		padding: 0;
	}

	.bx-soa-item-info-container {
		position: relative;
		min-height: 0;
		height: auto !important;
	}
}

/* Coupon */
.bx-soa-coupon {
	margin-top: map-get($spacers, 5);
	padding-top: map-get($spacers, 7);
	padding-bottom: map-get($spacers, 4);

	display: bloc;

	&:after {
		position: absolute;
		content: "";
		width: calc(100% - #{$grid-gutter-width});
		left: $grid-gutter-width / 2;
		height: 1px;
		/* top: -30px; */
		margin-top: -8.13rem;;
		background: $body-bg;
	}
}

.bx-soa-coupon-label {
	padding-right: map-get($spacers, 3);
	color: $extra;
	vertical-align: top;
	font-size: $font-size-sm;
}

.bx-soa-coupon-item-fixed .bx-soa-coupon-block { font-weight: normal; }

.bx-soa-coupon-item-fixed .bx-soa-coupon-label,
.bx-soa-coupon-item-fixed .bx-soa-coupon-item {
	padding-top: 0;
	font-weight: normal;
	line-height: 20px;
}

.bx-soa-coupon-block {
	vertical-align: middle;
	max-width: 200px;
}

.bx-soa-coupon-input {
	position: relative;
}

.bx-soa-coupon-input:after {
	position: absolute;
	top: 50%;
	right: 10px;
	display: block;
	margin-top: -4px;
	width: 8px;
	height: 8px;
	border-right: 2px solid #8da3ad;
	border-bottom: 2px solid #8da3ad;
	background-color: transparent;
	content: "";
	cursor: pointer;
	transform: rotate(-45deg);
}

.bx-soa-coupon-input input[type=text].form-control {

}

.bx-soa-coupon-input input[type=text].form-control:focus {

}


.bx-soa-coupon-item {
	margin-top: 1rem;
	width: 100%;
	display: block;
}

.bx-soa-coupon-item strong {
	display: flex;
	align-items: center;
	margin-bottom: map-get($spacers, 3);
	font-weight: 400;

	.bx-soa-tooltip.bx-soa-tooltip-coupon {
		display: block !important;
		display: block;
		position: relative;
		opacity: 1;
		margin-left: map-get($spacers, 3);
	}

	.bx-soa-tooltip .tooltip-inner {
		padding: 0;
	}
}

.bx-soa-coupon-item strong.bx-soa-coupon-item-danger { color: theme-color('danger'); }

.bx-soa-coupon-item strong.bx-soa-coupon-item-success { color: theme-color('success'); }

.bx-soa-coupon-item strong.bx-soa-coupon-item-used { color: theme-color('info'); }

.bx-soa-coupon-item-danger-mess,
.bx-soa-coupon-item-mess {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
	display: none;
	padding: 6px 10px;
	min-width: 100%;
	border: 1px solid #ddd;
	border-radius: 3px;
	background: #fff;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
	color: #333;
	text-transform: none;
	letter-spacing: normal;
	font-weight: normal;
}

.bx-soa-coupon-item strong:hover .bx-soa-coupon-item-mess,
.bx-soa-coupon-item strong:hover .bx-soa-coupon-item-danger-mess { display: block; }

.bx-soa-coupon-remove {
	position: relative;
	display: inline-block;
	margin-left: map-get($spacers, 3);
	width: 1.25rem;
	height: 1.25rem;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAANhJREFUeNpEkM0KwjAQhGdr/Dv6Nj6EXkRvehTfRhS868lWWigU9OFEHGeTqIF0dydftrMJp0s1McMdwJ5AY0iLKaxVbwGbBxgeJKaCS4NjbJxQvlE4a/ektUFX9w5JCNolEzQmGCHlT8VjEFQjdvrBN0EOF4oOrXS5Cxmqs7NKVSEroPHlkLLOLRX4r5HOjenf3tE7DDyjxCLNx032ZFovSe80BK7Sl9IQmJ4gT4foSfowe+47jGx+xzidQxY9Zd/+/cLbIC8ztW9VHBL0fXDWGdeDc/ERYABB6E45iF95UQAAAABJRU5ErkJggg==) no-repeat center;
	vertical-align: middle;
	cursor: pointer;
}

/* pickup */
#pickUpMap img {
	max-height: initial;
}

.bx-soa-pickup-preview-img {
	float: right;
	margin-left: 10px;
	padding: 1px;
	height: 76px;
	border: 1px solid #ddd;
	border-radius: 1px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.bx-soa-pickup-subTitle {
	padding-bottom: 10px;
	font-weight: bold;
	font-size: 15px;
}

.bx-soa-pickup-list { margin-bottom: 20px; }

.bx-soa-pickup-list .bx-soa-pickup-list-item {
	position: relative;
	padding: 16px 35px;
	border-top: 1px solid #dcdfe2;
	font-size: 13px;
	transition: background .2s ease;
}

.bx-soa-pickup-list-item.bx-selected { background: #f8fafc; }

.bx-soa-pickup-list .bx-soa-pickup-list-item:last-child { border-bottom: 1px solid #dcdfe2; }

.bx-soa-pickup-l-item-adress {
	position: relative;
	padding-right: 100px;
	padding-left: 15px;
	min-height: 17px;
	color: #25495e;
	font-weight: bold;
	line-height: 17px;
}

.bx-soa-pickup-l-item-adress:before {
	position: absolute;
	top: 50%;
	left: -5px;
	margin-top: -8px;
	width: 18px;
	height: 17px;
	background: url(images/sprite.png) no-repeat center -54px;
	content: "";
}

.bx-soa-pickup-list-item .bx-soa-pickup-l-item-detail {
	display: none;
	padding-top: 20px;
}

.bx-soa-pickup-list-item.bx-selected .bx-soa-pickup-l-item-detail { display: block; }

.bx-soa-pickup-l-item-detail {
	position: relative;
	padding-left: 100px;
	min-height: 80px;
}

.bx-soa-pickup-l-item-detail.no-image { padding-left: 20px; }

.bx-soa-pickup-l-item-btn {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -17px;
}

.bx-soa-pickup-list-item.bx-selected .bx-soa-pickup-l-item-btn {
	position: relative;
	top: auto;
	right: auto;
	float: none;
	margin-top: 0;
	padding-top: 10px;
	padding-left: 100px;
}

.bx-soa-pickup-list-item.bx-selected .bx-soa-pickup-l-item-btn.no-image { padding-left: 20px; }

.bx-soa-pickup-l-item-name { font-weight: bold; }

.bx-soa-pickup-l-item-img {
	position: absolute;
	top: 23px;
	left: 0;
	max-width: 90px;
	max-height: 90px;
	width: auto;
	height: auto;
}

/*Sidebar*/
.bx-soa-sidebar {
	position: relative;
	height: 100%;
}

.bx-soa-cart-total {
	padding: 20px;
	border: 1px solid #e8e9eb;
	border-radius: 3px;
	background-color: #fff;
	transition: opacity .2s ease;
	box-shadow: $block-shadow-default;
}

.bx-soa-cart-total-heading {
	padding: 1rem 0 2rem;
	text-align: center;
}

.bx-soa-cart-total.bx-soa-cart-total-fixed {
	position: fixed;
	top: 10px;
}

.bx-soa-cart-total.bx-soa-cart-total-bottom { opacity: 0; }

.bx-soa-section-title-icon {
	color: theme-color('primary');
	position: absolute;
	left: -2.63rem;
	line-height: 1;

	.bx-soa-section:not(.bx-step-completed) & > .icon-svg.is-completed {
		display: none;
	}

	.bx-soa-section.bx-step-completed  & > .icon-svg:not(.is-completed) {
		display: none;
	}
}



@media (max-width: 768px) {
	.bx-soa-cart-total.bx-soa-cart-total-fixed {
		position: static;
		padding-top: 0;
		width: 100% !important;
	}

	.bx-soa-cart-total.bx-soa-cart-total-bottom {
		position: static;
		opacity: 1 !important;
	}

	.bx-soa-cart-total-ghost { display: none; }
}

.bx-soa-cart-total-line {
	overflow: hidden;
	padding: map-get($spacers, 3) map-get($spacers, 3);
	border-top: 1px dashed $body-bg;
	font-size: $font-size-base;
}

.bx-soa-cart-total .bx-soa-cart-total-line:first-child { border-top: none; }

.bx-soa-cart-total .bx-soa-cart-total-line:nth-child(2) { border-color: $border-color; }

.bx-soa-cart-total .bx-soa-cart-total-line-total {
	padding-top: 25px;
	border-bottom: none;
}

.bx-soa-cart-t { float: left; }

.bx-soa-cart-d {
	float: right;
	color: $body-color;
	text-align: right;
}


.bx-soa-cart-total .bx-soa-cart-total-line-total .bx-soa-cart-d {
	font-size: 1.25rem;
	font-weight: bold;
}

.bx-soa-cart-total-line.bx-soa-cart-total-line-highlighted {
	border-top: 0;
}

.bx-soa-cart-total-line-highlighted + .bx-soa-cart-total-line {
	margin-top: 10px;
	border-top: 1px dashed rgb(189, 189, 189);
}

.bx-soa-cart-total-button-container {
	padding-top: 20px;
	text-align: center;
}

.bx-soa-cart-total-button-container > .btn { width: 100%; }

.bx-soa-more {
	margin-top: map-get($spacers, 5);
	padding-top: map-get($spacers, 6);

	&:after {
		position: absolute;
		content: "";
		width: calc(100% - #{$grid-gutter-width});
		left: $grid-gutter-width / 2;
		height: 1px;
		/* top: -30px; */
		margin-top: -2.19rem;
		background: $body-bg;
	}
}

.bx-soa-more-btn {
	display: flex;
	justify-content: flex-end;

	.btn + .btn {
		margin-left: map-get($spacers, 3);
	}
}

.bx-soa-price-not-calc {
	border-bottom: 1px dashed;
	color: #f55450;
	text-decoration: none;
	cursor: pointer;
}

.bx-soa-price-not-calc:active,
.bx-soa-price-not-calc:hover {
	border-color: transparent;
	color: #a94442;
}

.bx-soa-price-free { color: #257210; }

@media (max-width: 991px) {
	.bx-soa-cart-total .bx-soa-cart-total-line-total .bx-soa-cart-d {
		white-space: nowrap;
		font-size: 19px;
	}

	.bx-soa-cart-total .bx-soa-cart-total-line-total .bx-soa-cart-d span { font-size: 14px; }
}

@media (max-width: 580px) {
	.bx-soa-coupon:after { margin-top: -7rem; }
	.bx-soa-coupon-label {
		display: block;
		padding-bottom: 5px;
	}

	.bx-soa-coupon-block { display: block; }

	.bx-soa-coupon-input { text-align: center; }

	.bx-soa-coupon-input input { display: inline-block !important; }

	.bx-soa-coupon-item { text-align: center; }

	.bx-soa-coupon-item strong {
		position: relative;
		display: inline-block;
	}

	.bx-soa-coupon-item strong:first-child { margin-left: 0; }

	.bx-soa-coupon-remove:after {
		clear: both;
		content: "";
	}

	.bx-soa-more { padding-top: 10px; }

	.bx-soa-pickup-l-item-detail { padding-left: 0; }

	.bx-soa-pickup-l-item-img {
		position: relative;
		top: auto;
		left: auto;
		max-width: 200px;
		max-height: 200px;
	}

	.bx-soa-pickup-l-item-adress { padding-right: 0; }
}

/* SCU */
.bx-scu-container { overflow: hidden; }

.bx-soa-item-content .bx-scu-container { max-width: 200px; }

.bx-scu-block { }

.bx-scu-list {
	margin: 0;
	padding: 0 5px;
}

.bx-scu-itemlist {
	overflow: hidden;
	margin: 0 -5px;
	padding: 0;
	min-width: 40px;
	list-style: none;
}

.bx-scu-item {
	float: left;
	padding: 5px;
	width: 20%;
}

.bx-img-item {
	float: left;
	padding: 5px;
	min-width: 30px;
	max-width: 50px;
	width: 50%;
}

@media (max-width: 580px) {
	.bx-img-item { width: 45px; }

}

.bx-img-itemColor {
	position: absolute;
	top: 3px;
	right: 3px;
	bottom: 3px;
	left: 3px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.bx-scu-itemColorBlock {
	position: relative;
	padding-top: 100%;
	outline: 1px solid #c0cfda;
	cursor: pointer;
	transition: outline .2s ease;
}

.bx-scu-itemColorBlock:hover { outline: 1px solid #006cc0; }

.bx-scu-itemColorBlock.selected { outline: 2px solid #006cc0; }

.bx-scu-itemColor {
	position: absolute;
	top: 3px;
	right: 3px;
	bottom: 3px;
	left: 3px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.bx-scu-itemTextBlock {
	position: relative;
	outline: 1px solid #c0cfda;
	cursor: pointer;
	transition: outline .2s ease;
}

.bx-scu-itemTextBlock:hover { outline: 1px solid #006cc0; }

.bx-scu-itemTextBlock.selected { outline: 2px solid #006cc0; }

.bx-scu-itemText {
	overflow: hidden;
	vertical-align: middle;
	text-align: center;
	text-transform: uppercase;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 11px;
	line-height: 18px;
}

/*.bx-step-completed*/
.bx-scu-container {
	position: relative;
	overflow: hidden;
	height: 35px;
}

/*.bx-step-completed*/
.bx-scu-container:after {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: 25px;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	content: "";
}

.bx-selected .bx-scu-container { height: auto; }

.bx-selected .bx-scu-container:after { height: 0; }

@media (max-width: 768px) {
	.bx-scu-container { height: auto; }

	.bx-scu-container:after { height: 0; }
}

/*.bx-step-completed*/
.bx-scu-container .bx-soa-item-td-title { line-height: 20px; }

.bx-scu-container .bx-soa-item-td-title,
.bx-scu-container .bx-soa-item-td-text {
	float: left;
	padding-right: map-get($spacers, 3);
	padding-bottom: map-get($spacers, 2);
	vertical-align: middle;
	font-size: $small-font-size;
}

.bx-scu-container .bx-soa-item-td-title { clear: both; }

@media (min-width: 768px) {
	.bx-scu-container .bx-soa-item-td-title, .bx-scu-container .bx-soa-item-td-text {
		padding-bottom: 0;
		line-height: 20px;
	}
}

/* AUTH */
.bx-soa h3.bx-title {
	font-weight: bold;
	font-size: 15px;
}

.bx-soa-reg-block {
	padding: 25px 20px;
	background: #f8fafc;
}

.bx-soa-reg-block p {
	margin: 0 0 10px;
	padding: 0;
	color: #595a5b;
	font-size: 13px;
}

/*bx-soa-reference*/
.bx-soa-reference {
	padding-top: map-get($spacers, 3);
	padding-bottom: map-get($spacers, 6);
	color: $extra;
	font-size: $small-font-size;
}

.bx-soa-auth .bx-soa-reference { border-top: 1px solid #f2f2f2; }

/*REGION*/
.bx-sls .quick-location-tag,
.bx-slst .quick-location-tag {
	display: inline-block;
	margin: 10px 2px;
	padding: 10px 20px 11px;
	height: auto;
	border: none;
	border-radius: 3px;
	background: #d0ecf1;
	color: #333;
	vertical-align: middle;
	font-size: 15px;
	line-height: 20px;
	cursor: pointer;
	transition: background 200ms ease;
}

.bx-sls .quick-location-tag:hover,
.bx-slst .quick-location-tag:hover { background-color: #e3f4f7; }

.bx-sls .quick-location-tag:first-child,
.bx-slst .quick-location-tag:first-child { margin-left: 0; }

.bx-sls .quick-location-tag:last-child,
.bx-slst .quick-location-tag:last-child { margin-right: 0; }

.bx-soa .bx-soa-location-offered small { color: #737373; }

.bx-soa-location-selected {
	padding-top: 12px;
	padding-bottom: 22px;
}

.bx-soa .bx-soa-customer-label,
/*.bx-soa .bx-soa-custom-label,*/
.bx-soa .form-group label,
.bx-soa .form-check-group > label{
	position: relative;
	padding-bottom: map-get($spacers, 2);
	color: $body-color;
	font-weight: 500;
	font-size: 1rem;
}

.bx-soa .bx-soa-customer-label-container small,
/*.bx-soa .bx-soa-custom-label-container small,*/
.bx-soa .form-group label small { color: #737373; }

/*FIELDS*/

.bx-soa .bx-soa-location-input-container { position: relative; }

.bx-soa .bx-soa-customer-tel-input-container { position: relative; }

.bx-soa .bx-soa-customer-tel-input-container { height: 41px; }

.bx-soa .bx-soa-location-input { padding: 10px 40px 11px; }

.bx-soa .bx-soa-customer-tel-input { padding: 10px 11px 10px 50px; }

.bx-soa .bx-soa-location-input-container:before {
	position: absolute;
	top: 9px;
	bottom: 9px;
	left: 8px;
	width: 20px;
	background-size: contain;
	content: " ";
}

/*pay*/
.bx-soa-pp-company {
	position: relative;
	z-index: 100;
	padding-bottom: 20px;
}

.bx-soa-pp .bx-soa-pp-company {
	padding: map-get($spacers, 4);
	border: 1px solid transparent;
    transition: all 100ms ease;

}


.bx-soa-pp {
    align-items: flex-start;
}



.bx-soa-pp-desc-container .bx-soa-pp-company {
	padding: 1.25rem 1.7rem;
	background: #f8f9fb;
}

.bx-soa-pp-company-logo { padding-top: 1rem; }

.bx-soa-pp-company-graf-container {
	position: relative;
	padding-top: 50%;
	width: auto;
	height: 0;
	border: 1px solid $border-color;
	background: $white;
}

.bx-soa-pp-company.bx-selected .bx-soa-pp-company-graf-container { border-color: theme-color('primary'); }

.bx-soa-pp-inner-ps.bx-selected .bx-soa-pp-company-graf-container { border-color: theme-color('primary'); }

.bx-soa-pp-company.bx-bd-error .bx-soa-pp-company-graf-container {border-color: theme-color('danger'); }

.bx-soa-pp-company.bx-bd-warning .bx-soa-pp-company-graf-container { border-color: theme-color('warning'); }
.bx-soa-pp-company.bx-bd-waring .bx-soa-pp-company-graf-container { border-color: theme-color('warning'); }

.form-check-group{ margin-bottom: 10px;}

.bx-soa-pp-company-checkbox {
	position: absolute;
	top: -7px;
	left: 4px;
	z-index: 100;
	margin: 0;
	padding: 0;
}

.bx-soa-pp-company-image {
	position: absolute;
	top: 5px;
	right: 5px;
	bottom: 5px;
	left: 5px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.bx-soa-pp-desc-container .bx-soa-pp-company-logo { max-width: 150px; }

.bx-soa-pp-company-smalltitle {
	padding-top: 9px;
	padding-bottom: 5px;
	color: #a3a3a3;
	text-align: center;
	font-size: 11px;
}

.bx-soa-pp-company-title {
	font-weight: bold;
	font-size: $h5-font-size;
}

.bx-soa-pp-company-subTitle {
	font-weight: bold;
}

.bx-soa-pp-company-desc {
	padding-top: map-get($spacers, 3);
	color: $extra;
	font-size: $font-size-sm;
}

.bx-soa-pp-company-selected { padding: 4px 0; }

.bx-soa-pp-company-selected strong { margin-left: 10px; }

ul.bx-soa-pp-list {
	margin: map-get($spacers, 4) 0 0;
	padding: 0;
	color: $body-color;
	list-style: none;
	font-size: $font-size-sm;
}

ul.bx-soa-pp-list li {
	clear: both;
	display: flex;
}

.bx-soa-pp-list-termin {
	display: block;
	margin-right: map-get($spacers, 3);
}

.bx-soa-pp-list-description { display: block; }

.bx-soa-pp-delivery-cost {
	position: absolute;
	right: -2px;
	bottom: -2px;
	padding: 2px 4px;
	border-radius: 1px;
	background: rgba(0, 0, 0, .5);
	color: #fff;
	font-size: 14px;
	line-height: 14px;
}

.bx-soa-pp-field .form-control { background: #fff; }

.bx-soa-pp-company-block .checkbox label,
.bx-soa-pp-company-block .form-group label { font-size: 13px; }

.bx-soa-pp-company-block .form-control { font-size: 13px; }

.bx-soa-pp-price {
	padding: 4px 0;
	text-align: right;
}

.bx-soa-service-small {
	color: #a3a3a3;
	font-size: 11px;
}

.bx-soa-pp-item-container {
    display: flex;
    flex-wrap: wrap;
}

#bx-soa-total-mobile {
    margin-bottom: 25px !important;
}

/*CHECKBOX*/


@media (min-width: 481px) and (max-width: 766px) {
	.bx-soa-pp-desc-container .bx-soa-pp-company-logo {
		padding-top: 0;
		width: 100%
	}

	.bx-soa-pp-desc-container .bx-soa-pp-company-smalltitle { display: none; }

	.bx-soa-pp-list-termin { width: 30%; }
}

@media (max-width: 766px) {
	.bx-soa-pp-company-logo { width: 45%; }

	.bx-soa-pp-company-title { display: none; }

	.bx-soa-pp-item-container { border-right: none; }

	.bx-soa-pp-desc-container { }

	.bx-soa-pp-desc-container .bx-soa-pp-company-logo {
		padding-top: 0;
		width: 100%
	}

	.bx-soa-pp-desc-container .bx-soa-pp-company-smalltitle { display: none; }

	.bx-soa-pp-list-termin { width: 40%; }

	.bx-soa-section-title-icon {
		display: none;
	}

	.bx-soa-section-title-container,
	.bx-soa-section-content {
		padding: 1rem;
	}
}

.bx-retina .bx-soa-location-input-container { border-width: .5px; }

.bx-soa-section.bx-step-good {
	transform-origin: 0 0;
	animation: animationBorderStep ease 5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes animationBorderStep {
	0% { border-color: #70bb18 }
	100% { border-color: #91cedc }
}

.bx-soa-section.bx-step-bad {
	transform-origin: 0 0;
	animation: animationBorderStepBad ease 5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes animationBorderStepBad {
	0% { border-color: #db4a29 }
	100% { border-color: #91cedc }
}

.bx-soa-changeCostSign {
	transform-origin: 0 0;
	animation: changeCostSign ease .4s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes changeCostSign {
	0%, 100% { opacity: 1 }
	40%, 60% { opacity: 0 }
}

.bx-sls.form-control { padding: 0; }

.bx-sls .bx-ui-sls-pane,
.bx-slst .bx-ui-combobox-dropdown { border: 1px solid #bdbdbd; }

.bx-sls .dropdown-fade2white,
.bx-slst .dropdown-fade2white { top: 1px; }

.bx-sls .form-control.dropdown-block,
.bx-slst .form-control.dropdown-block {
	border: 1px solid #d5dadc;
	background-color: #f8fafc;
	box-shadow: none;
}

.has-error .bx-sls .form-control.dropdown-block,
.has-error .bx-slst .form-control.dropdown-block {
	border-color: #a94442;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.bx-slst .form-control .bx-ui-combobox-container .bx-combobox-fake-as-input { padding-top: 7px; }

.bx-bd-error { border-color: #db4a29; }

.bx-bd-waring { border-color: #ccbe71; }

.wsnw { white-space: nowrap; }

.bx-step-opacity {
	transition: 500ms opacity ease;
}

/* MAPS */
ymaps h3 { margin: 6px 0 10px 0; }

/* AUTH */
.bx-authform { margin: 0 0 25px; }

/*block*/

span.bx-authform-starrequired { color: #d93333; }

hr.bxe-light {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-bottom: 1px solid #e5e5e5;
}

.alert {
	&.alert-basket-custom-errors {
		padding-top: 2rem;
		padding-bottom: 2rem;

		.icon-svg {
			width: 1.5rem;
			height: 1.5rem;
			margin-top: -.25rem;
			margin-right: .5rem;
		}
	}
}


.bxe-aleft-title {
	padding-bottom: 5px;
	font-weight: bold;
}

.alert-success {
	border-color: #d6e9c6;
	background-color: #dff0d8;
	color: #3c763d;
}

.alert-warning {
	border-color: #faebcc;
	background-color: #fcf8e3;
	color: #8a6d3b;
}

.alert-danger {
	border-color: #ebccd1;
	background-color: #f2dede;
	color: #a94442;
}

/* PAGINATION */
.bx-pagination { margin: 10px 0; }

.bx-pagination .bx-pagination-container {
	position: relative;
	text-align: center;
}

.bx-pagination .bx-pagination-container ul {
	display: inline-block;
	margin: 0;
	padding: 0;
	list-style: none;
}

.bx-pagination .bx-pagination-container ul li {
	display: inline-block;
	margin: 0 2px 15px 2px;
}

.bx-pagination .bx-pagination-container ul li span {
	display: block;
	box-sizing: border-box;
	padding: 0 8px;
	min-width: 32px;
	height: 32px;
	border-radius: 16px;
	background: #eff0f1;
	color: #444;
	vertical-align: middle;
	font-size: 14px;
	line-height: 32px;
	transition: color .3s ease, background-color .3s ease, border-color .3s ease;
}

.bx-pagination .bx-pagination-container ul li a {
	display: block;
	min-width: 32px;
	height: 32px;
	border-radius: 16px;
	color: #444;
	vertical-align: middle;
	text-decoration: none;
	line-height: 32px;
}

.bx-pagination .bx-pagination-container ul li a:hover span { background: #dadada; }

.bx-pagination .bx-pagination-container ul li.bx-active span {
	background: #0083d1;
	color: #fff;
}

.bx-pagination .bx-pagination-container ul li.bx-pag-all a span,
.bx-pagination .bx-pagination-container ul li.bx-pag-prev a span,
.bx-pagination .bx-pagination-container ul li.bx-pag-next a span {
	padding: 0 18px;
	border: 2px solid #eff0f1;
	background: #fff;
	color: #444;
	line-height: 28px;
}

.bx-pagination .bx-pagination-container ul li.bx-pag-all span,
.bx-pagination .bx-pagination-container ul li.bx-pag-prev span,
.bx-pagination .bx-pagination-container ul li.bx-pag-next span {
	padding: 0 18px;
	border: 2px solid #eff0f1;
	background: #fff;
	color: #aab1b4;
	line-height: 28px;
}

.bx-pagination .bx-pagination-container ul li.bx-pag-all a:hover span,
.bx-pagination .bx-pagination-container ul li.bx-pag-prev a:hover span,
.bx-pagination .bx-pagination-container ul li.bx-pag-next a:hover span {
	border-color: $border-color;
	background: $white;
	color: $body-color;
}

.bx-pagination.bx-green .bx-pagination-container ul li.bx-active span {
	background: #3bc915;
	color: $white;
}

.bx-pagination.bx-yellow .bx-pagination-container ul li.bx-active span {
	background: #f9a91d;
	color: $white;
}

.bx-pagination.bx-red .bx-pagination-container ul li.bx-active span {
	background: #e22b2b;
	color: $white;
}

#loading_screen {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 1150;
	margin-top: -32px;
	margin-left: -32px;
	width: 64px;
	height: 64px;
}

.bx-soa-tooltip.bx-soa-tooltip-static {
	position: relative;
	z-index: 0;
}

.bx-soa-tooltip.bx-soa-tooltip-static .tooltip-arrow { left: 20px; }

.bx-soa-tooltip.bx-soa-tooltip-danger .tooltip-arrow { border-top-color: theme-color('danger'); }

.bx-soa-tooltip.bx-soa-tooltip-danger .tooltip-inner { color: theme-color('danger'); }

.bx-soa-tooltip.bx-soa-tooltip-warning .tooltip-arrow { border-top-color: theme-color('warning'); }

.bx-soa-tooltip.bx-soa-tooltip-warning .tooltip-inner { color: theme-color('warning'); }

.bx-soa-tooltip.bx-soa-tooltip-success .tooltip-arrow { border-top-color: theme-color('success'); }

.bx-soa-tooltip.bx-soa-tooltip-success .tooltip-inner {  color: theme-color('success'); }

.bx-soa-tooltip.bx-soa-tooltip-coupon { display: none; }

.bx-soa-tooltip .tooltip-inner {
	max-width: 100%;
	background: transparent;
	text-align: left;
	padding-left: 0;
	padding-right: 0;
}

.bx-soa-coupon-item-used:hover .bx-soa-tooltip,
.bx-soa-coupon-item-danger:hover .bx-soa-tooltip,
.bx-soa-coupon-item-success:hover .bx-soa-tooltip {
	bottom: 100%;
	display: block;
	opacity: 1;
}

.bx-soa-tooltip-coupon > span {
	display: block;
	text-transform: none;
}

.bx-soa-empty-cart-image {
	margin: 50px auto 42px;
	width: 131px;
	height: 116px;
	background: url(images/empty_cart.svg) no-repeat center;
	background-size: contain;
}

.bx-soa-empty-cart-text {
	margin-bottom: 42px;
	color: #bababa;
	text-align: center;
	font-size: 36px;
}

.bx-soa-empty-cart-desc {
	margin-bottom: 42px;
	color: $body-color;
	text-align: center;
	font-size: 16px;
}

.soa-property-container .input-group-addon { background: #fff; }

.input-group-addon .bx-calendar {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url(images/calendar.svg) no-repeat center;
	background-size: 18px auto;
}

.icon-success {
	position: absolute;
	left: 16px;
	width: 21px;
	height: 21px;
	background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjEgMjEiPjxzdHlsZT4uc3Qwe2ZpbGw6IzcxYmEyNH08L3N0eWxlPjxwYXRoIGlkPSJGb3JtYV8xIiBjbGFzcz0ic3QwIiBkPSJNMTAuNSAwQzQuNyAwIDAgNC43IDAgMTAuNVM0LjcgMjEgMTAuNSAyMSAyMSAxNi4zIDIxIDEwLjUgMTYuMyAwIDEwLjUgMHpNOSAxNS40bC01LTQuOSAyLTIgMyAzIDYtNS45IDIgMi04IDcuOHoiLz48L3N2Zz4=);
	background-size: contain;
}

.icon-warning {
	position: absolute;
	left: 16px;
	width: 22px;
	height: 20px;
	background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjIgMjAiPjxzdHlsZT4uc3Qwe2ZpbGw6I2RlYTcxMn08L3N0eWxlPjxwYXRoIGlkPSJGb3JtYV8xIiBjbGFzcz0ic3QwIiBkPSJNMjEuNyAxNi43TDEyLjkgMS4xQzEyLjMuMSAxMS0uMyAxMCAuM2MtLjQuMi0uNy41LS45LjhMLjMgMTYuN2MtLjQuNy0uNCAxLjUgMCAyLjIuNC43IDEuMSAxLjEgMS45IDEuMWgxNy43Yy44IDAgMS41LS40IDEuOS0xLjEuMy0uNy4zLTEuNS0uMS0yLjJ6bS0xMC43LjhjLS44IDAtMS40LS42LTEuNS0xLjQgMC0uOC42LTEuNCAxLjQtMS41LjggMCAxLjQuNiAxLjUgMS40IDAgLjgtLjYgMS41LTEuNCAxLjV6bTEuNC02LjJjMCAuOC0uNiAxLjQtMS40IDEuNHMtMS40LS42LTEuNC0xLjRWNC43YzAtLjQuMy0uNy43LS43aDEuNWMuNCAwIC43LjMuNy43bC0uMSA2LjZ6Ii8+PC9zdmc+);
	background-size: contain;
}

/* USER CONSENT */
label[data-bx-user-consent] { line-height: 13px; }

label[data-bx-user-consent] a {
	color: $body-color;
	font-weight: 100;
	font-size: 11px;
}

label[data-bx-user-consent] input { margin-top: 2px; }

.bx-sls .bx-ui-sls-container { min-height:33px !important;}


.bx-soa-order-save {
	display: flex;
	align-items: center;
	flex-direction: column;
}


.order-success-container {
	max-width: 43.75rem;
	margin: auto;
	padding: 6.25rem 0;
}
