@import '../../../../resources/sass/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '../../../../resources/sass/snippets/all';

$filter-class: '.bx-filter' !default;

#{$filter-class} {
	width: 100%;

	.fancybox-content & {
		background: $body-bg;
	}

	&-section {
		position: relative;
		// padding-top: $line-height-computed;

		& > .row {
			padding: 0 5px;
		}
	}

	&-container {

		&-modef {

			display: none;

			@include media-breakpoint-up(sm) {
				display: block;
			}
		}
	}

	&-parameters-box {
		position: relative;
		margin-bottom: map-get($spacers, 1);

		&-title {
			background-color: $white;
			cursor: pointer;
			display: block !important;
			position: relative;
			word-break: break-word;
		}

		&-drop-all,
		&-angle {
			position: absolute;
			font-weight: $font-weight-bold;
			display: inline-block;
			margin: auto;
			right: map-get($spacers, 4);
			top: #{map-get($spacers, 3) + map-get($spacers, 2)};
		}

		&-drop-all {
			display: none;
		}

		&-title {
            $padding-y: #{map-get($spacers, 3) + map-get($spacers, 2)};
			padding: $padding-y map-get($spacers, 7) map-get($spacers, 4) $padding-y;
		}
	}

	&-block {
		@extend %clearfix;

		background-color: theme-color('light');
		box-sizing: content-box;
		display: none;
		transition: padding .3s ease;

		.bmd-radio,
		.bmd-custom-checkbox > label .checkbox-decorator {
			// box-sizing: content-box;
			// text-align: center;
			// width: calc(1.75rem + 2px);
			left: .25rem; // centered with color-icon
		}

		.bmd-custom-radio > label,
		.bmd-custom-checkbox > label {
			padding-left: 40px;
		}
	}

	&-button-box &-button-box-inner {
		padding: map-get($spacers, 5) map-get($spacers, 7);
	}

	&-button-box &-block,
	&-parameters-box.bx-active &-block {
		display: block;
	}

	&-parameters-box-container {
		padding: map-get($spacers, 4);

		.checkbox:first-child {
			// margin-top: 0;
		}

		&-block {
			& + & {
				&:before {
					background-color: $extra;
					bottom: 0;
					content: '';
					display: inline-block;
					height: 1px;
					left: -7px;
					margin: auto;
					position: absolute;
					top: 0;
					width: 13px;
				}
			}
		}
	}

	&-button-box &-block {
		// border: 1px solid $border-color;
	}
	&-button-box &-parameters-box-container {
		// padding-bottom: map-get($spacers, 5);
		// padding-top: map-get($spacers, 5);
		// text-align: center;
	}


	&-scroll{
		//max-height: $line-height-computed*1.5*7;
		max-height: 167px;
		overflow: auto;
		padding-top: 1px;

		.scroll-element {
			background: #f7f7f8;
		}
	}

	/*
	*Track
	*
	*/

	.bx-ui-slider-track-container {
		padding-bottom: 15px;
		padding-top: 28px;
	}

	.bx-ui-slider-track {
		position: relative;
		height: 3px;
		background: #d7dbdd;
	}
	.bx-ui-slider-part {
		display: none;
		position: absolute;
		top: -6px;
		width: 1px;
		height: 17px;
		background: #a2bfc7;
	}
	.bx-ui-slider-part.p1 {
		left: -1px;
	}
	.bx-ui-slider-part.p2 {
		left: 25%;
	}
	.bx-ui-slider-part.p3 {
		left: 50%;
	}
	.bx-ui-slider-part.p4 {
		left: 75%;
	}
	.bx-ui-slider-part.p5 {
		right: -1px;
	}
	.bx-ui-slider-part span {
		font-size: $small-font-size;
		/*font-weight: bold;*/

		position: absolute;
		top: -16px;
		left: 50%;
		display: block;
		width: 100px;
		margin-left: -50px;
		text-align: center;
		color: #000;
	}
	.bx-ui-slider-part.p2 span,
	.bx-ui-slider-part.p3 span,
	.bx-ui-slider-part.p4 span {
		color: #6b8086;
	}
	.bx-ui-slider-range,
	.bx-ui-slider-pricebar,
	.bx-ui-slider-pricebar-vd,
	.bx-ui-slider-pricebar-vn,
	.bx-ui-slider-pricebar-v {
		position: absolute;
		top: 0;
		bottom: 0;
		min-width: 1px;
	}
	.bx-ui-slider-range {
		z-index: 90;
	}
	.bx-ui-slider-pricebar {
		z-index: 100;
	}
	.bx-ui-slider-pricebar-vd {
		z-index: 60;
		background: #b1b1b1;
	}
	.bx-ui-slider-pricebar-vn {
		z-index: 70;
		background: #f2f2f2;
	}
	.bx-ui-slider-pricebar-v {
		z-index: 80;
		background: theme-color('primary');
	}
	.bx-ui-slider-handle {
		border-radius: 50%;
		background-color: theme-color('primary');
		height: 21px;
		position: absolute;
		top: -9px;
		transform: scale(.571);
		transition: transform .1s ease-out;
		width: 21px;
	}

	.bx-ui-slider-handle:hover {
		cursor: grab;
	}

	.bx-ui-slider-handle.active {
		transform: scale(1);
	}

	.bx-ui-slider-handle:active {
		cursor: grabbing;
	}
	.bx-ui-slider-handle.left {
		left: 0;
		margin-left: -1*map-get($spacers, 3);
	}
	.bx-ui-slider-handle.right {
		right: 0;
		margin-right: -1*map-get($spacers, 3);
	}

	&-input-container {

		.form-control {
			height: 100%;
			-moz-appearance: textfield;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				/* display: none; <- Crashes Chrome on hover */
				-webkit-appearance: none;
				margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
			}
		}
	}

	&-input-checkbox,
	&-param-label {
		padding-top: map-get($spacers, 4);
	}

	&-input-checkbox:first-of-type {
		padding-top: map-get($spacers, 3);
	}
	&-param-label:first-of-type {
		padding-top: 0;
	}


	/*
	*	LABEL
	*
	*/
	&-param-label {
		cursor: pointer;
		display: block;
		font-weight: normal;
		margin: 0;
	}

	.dropdown-item &-param-label,
	&-input-checkbox &-param-label {
		//margin-bottom: map-get($spacers, 3);
		padding-top: 0;
	}

	&-param-btn-inline &-param-label {
		float: left;
		margin: 0 map-get($spacers, 2) map-get($spacers, 2) 0;
		padding: 0;
	}

	&-param-label.disabled,
	&-param-label.disabled &-param-text {
		color: $gray-500;
		cursor: inherit;
	}

	/*
	*	 BTN
	*
	*/

	&-param-label {
		@extend %color-item;
	}

	&-param-btn {

		&.bx-color-sl {
			@extend %color-item__btn;

			margin-left: -2px; // color-item borders
		}
	}

	&-param-label.disabled {
		pointer-events: none;
	}

	&-param-label.disabled > &-param-btn.bx-color-sl {
		border-color: transparent;
		cursor: inherit;
	}

	&-btn-color-icon {
		@extend %color-item__icon;

		display: inline-block;

		&.all {
			position: relative;
			border: none;
			box-shadow: none;
			fill: transparent;
			font-size: 1.5rem; // %color-item size - borders;
			stroke: currentColor;
		}

//		&.all:after,
//		&.all:before {
//			position: absolute;
//			width: 60%;
//			height: 60%;
//			background: #fff;
//			content: " ";
//			box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .2);
//		}
//		&.all:after {
//			right: 0;
//			bottom: 0;
//		}
//		&.all:before {
//			top: 0;
//			left: 0;
//		}
	}

	&-param-btn + &-param-text,
	&-btn-color-icon + &-param-text {
		margin-left: map-get($spacers, 2);
		vertical-align: middle;
	}

	&-select-container &-btn-color-icon {
		// height: map-get($spacers, 4);
		// width: map-get($spacers, 4);
		// margin: -1*map-get($spacers, 2) auto;
	}

	.dropdown-item {
		// padding: $btn-padding-y $btn-padding-x;
	}

	.checked {
		&#{$filter-class}-param-label {
			#{$filter-class}-param-btn {
				&.bx-color-sl {
					border-color: #fff;
					&:hover {
						border-color: #7d8698;
					}
					&:before {
						content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M11.1897 2.50446C11.1897 2.33705 11.1228 2.16964 11.0022 2.04911L10.0915 1.13839C9.97098 1.01786 9.80357 0.950892 9.63616 0.950892C9.46875 0.950892 9.30134 1.01786 9.1808 1.13839L4.78795 5.53795L2.8192 3.5625C2.69866 3.44196 2.53125 3.375 2.36384 3.375C2.19643 3.375 2.02902 3.44196 1.90848 3.5625L0.997768 4.47321C0.877232 4.59375 0.810268 4.76116 0.810268 4.92857C0.810268 5.09598 0.877232 5.26339 0.997768 5.38393L3.42188 7.80804L4.33259 8.71875C4.45313 8.83929 4.62054 8.90625 4.78795 8.90625C4.95536 8.90625 5.12277 8.83929 5.2433 8.71875L6.15402 7.80804L11.0022 2.95982C11.1228 2.83929 11.1897 2.67188 11.1897 2.50446Z' fill='%23EEEEEE'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0.810265' y='0.950892' width='10.3795' height='8.95536' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='1'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
						position: absolute;
						width: 3px;
						height: 3px;
						top: 5px;
						left: 8px;
					}
				}
			}
		}
	}

	/*
	*	 Result Popup
	*
	*/
	&-popup-result {
		$popup-inner: map-get($spacers, 2) * 2 + $font-size-base * $line-height-base;
		$popup-border-size: 2px;
		$popup-corner-size: 1.5rem;

		@include button-size($btn-padding-y, 1.75rem, $font-size-base, $btn-line-height, 0);

		background: $price-economy-bg;
		box-shadow: $block-shadow-hover;
		display: none;
		margin: map-get($spacers, 3) auto;
		position: absolute;
		white-space: nowrap;
		z-index: 915;

		& > a {
			margin-left: map-get($spacers, 5);
		}

//		&:before,
//		&:after {
//			content: '';
//			display: block;
//			position: absolute;
//		}

		&.right {
			left: 93%;
//		margin-left: $popup-corner-size;

//		&:before {
//			border-style: solid;
//			border-color: transparent theme-color('primary') transparent transparent;
//			border-width: calc(#{$popup-inner/2} + #{$popup-border-size}) $popup-corner-size calc(#{$popup-inner/2} + #{$popup-border-size}) 0;
//			margin-left: -1*$popup-border-size;
//			left: -1*($popup-corner-size);
//			top: -1*$popup-border-size;
//		}

//		&:after{
//			border-style: solid;
//			border-color: transparent theme-color('light') transparent transparent;
//			border-width: $popup-inner/2 $popup-corner-size $popup-inner/2 0;
//			left: -1*$popup-corner-size;
//			top: 0;
//		}
		}

		&.left {
			right: 93%;
//		margin-right: $popup-corner-size;

//		&:before{
//			border-left: 25px solid theme-color('primary');
//			border-top: 27px solid transparent;
//			border-bottom: 27px solid transparent;
//			right: -27px;
//			top: -2px;
//		}

//		&:after{
//			border-left: 24px solid $body-bg;
//			border-top: 25px solid transparent;
//			border-bottom: 25px solid transparent;
//			right: -24px;
//			top: 0;
//		}
		}

		@include media-breakpoint-down(xs) {
			.bx-touch & {
				position: fixed !important;
				z-index: 1001;
				top: auto !important;
				right: 0 !important;
				bottom: 0 !important;
				left: 0 !important;
				margin-top: 0;
				margin-left: 0;
				width: auto;
				height: auto;
				padding: 10px;
				border-radius: 2px;
				border: 1px solid #c6dae7;
				background: rgba(255, 255, 255, .9);
			}
		}

		@include media-breakpoint-down(sm) {
			& {
				display: none !important;
			}
		}
	}
}

/*Sections*/
@include media-breakpoint-up(md) {
	#{$filter-class} #{$filter-class}-section .col-md-4:nth-child(3n+1) {
		clear: both;
	}
}

@import 'style.vertical';



