.l-page {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	// height: 100%;

	&__header,
	&__compact,
	&__footer {
		flex: 0 0 auto;
	}

	&__main {
		flex: 1 0 auto;
		z-index: 1;
	}

	&__compact {
		z-index: $zindex-dropdown + 3;
	}

	@include media-breakpoint-down(sm) {
		&__compact {
			margin-bottom: map-get($spacers, 6);
		}
	}
}
