%vcenter {
  white-space: nowrap;

  &:before {
    display: inline-block;
    content: '';
    height: 100%;
    vertical-align: middle;
    width: 0;
  }

  &__in {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
    white-space: normal;
  }
}
