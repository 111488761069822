%price-old {
	color: $extra;
//	display: inline-block;
	font-size: $font-size-sm;
	text-decoration: line-through;
//	vertical-align: map-get($spacers, 1);
	white-space: nowrap;
}

%price-current {
//	flex-wrap: wrap;
	font-weight: 600;
	font-size: $font-size-lg;
//	margin-top: -1*map-get($spacers, 3);
	white-space: nowrap;
}

%price-economy {
	background-color: $price-economy-bg;
	padding: 0 map-get($spacers, 2);
	white-space: nowrap;
}

%price-percent {
	background-color: #e83700;
	color: #fff;
}