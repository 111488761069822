$form-check-input-margin-y:			 .4rem;

@import '../../../../local/templates/megamart_default/resources/sass/variables';
@import 'bootstrap/scss/bootstrap';
@import '../../../../local/templates/megamart_default/resources/sass/mixins/all';

@import '../../../../local/templates/megamart_default/resources/sass/base/all';
@import '../../../../local/templates/megamart_default/resources/sass/components/nav';
@import '../../../../local/templates/megamart_default/resources/sass/utils/colors';

@import '../../../../local/templates/megamart_default/resources/sass/snippets/svg-icon-background';
@import '../../../../local/templates/megamart_default/resources/sass/utils/row';

html {
	font-size: initial !important;
}


body {
	font-family: $font-family-base !important;
	font-size: $font-size-base !important;
	font-weight: $font-weight-base !important;
	line-height: $line-height-base !important;
	color: $body-color !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	line-height: $headings-line-height !important;
}

@include media-breakpoint-down(lg) {
	.container {
		max-width: none;
	}
}

.form-control:focus {
	box-shadow: none;
	border-color: var(--primary);
}

@import '../../../../local/templates/megamart_default/resources/sass/layout/page';

.icon-svg {
	@extend %svg-icon-background;
}

.landing-layout-flex {
	@extend .container;

	&,
	.container {
	padding: 0;
	}
}

.landing-sidebar {
	padding-right: 1.25rem;
}

.l-section {
	&__head {
		@include make-block-spacing(('padding'), ('left', 'right'));
		padding-top: map-get($spacers, 5);
		padding-bottom: map-get($spacers, 5);
		min-height: $section-head-height; // paddings + owl-prev height;

		background-color: $white;
	}
}

.table.data-table {
	@extend .table;
}

.text-primary {
	color: var(--primary) !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
	background-color: var(--primary);
	border-color: var(--primary);
}

.popup-window {
	color: $body-color;
	font-family: $font-family-base;
	font-size: $font-size-base;
	font-weight: $font-weight-normal;
	line-height: $line-height-base;
	padding: map-get($spacers, 4);
}


.popup-window.popup-window-with-titlebar {
	padding: 0 map-get($spacers, 4) map-get($spacers, 4);
}

.popup-window-titlebar-text {
	color: $body-color;
	font-size: $font-size-base;
	font-weight: $headings-font-weight;
}

@import 'components/all';
